import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Dashboard.css';

function Employees() {
  const [employeesData, setEmployeesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    fetchEmployeesData();
  }, []);

  const fetchEmployeesData = async () => {
    try {
      setLoading(true);
      const response = await axios.get('/api/employees', {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setEmployeesData(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching employees data:', error);
      setError('Failed to load employees data. Please try again later.');
      setLoading(false);
    }
  };

  const handleBack = () => {
    navigate('/admin-dashboard');
  };

  if (loading) {
    return <div>Loading employees data...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="dashboard">
      <h1>Employees</h1>
      <button onClick={handleBack}>Back to Admin Dashboard</button>
      <div className="scrollable-table">
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Role</th>
              <th>Hourly Rate</th>
              <th>Access Level</th>
            </tr>
          </thead>
          <tbody>
            {employeesData.map((employee) => (
              <tr key={employee.id}>
                <td>{employee.name}</td>
                <td>{employee.role}</td>
                <td>${typeof employee.hourly_rate === 'number' ? employee.hourly_rate.toFixed(2) : 'N/A'}</td>
                <td>{employee.access_level}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Employees;