import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import Login from './components/Login';
import AdminDashboard from './components/AdminDashboard';
import DoctorReimbursements from './components/DoctorReimbursements';
import Payroll from './components/Payroll';
import Patients from './components/Patients';
import Employees from './components/Employees';
import PatientSessions from './components/PatientSessions';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userRole, setUserRole] = useState(null);

  const handleLogin = (role) => {
    console.log('Logging in with role:', role);
    if (role) {
      setIsAuthenticated(true);
      setUserRole(role);
      localStorage.setItem('isAuthenticated', 'true');
      localStorage.setItem('userRole', role);
    } else {
      console.error('Login attempted with undefined role');
    }
  };

  const handleLogout = () => {
    console.log('Logging out');
    setIsAuthenticated(false);
    setUserRole(null);
    localStorage.removeItem('isAuthenticated');
    localStorage.removeItem('userRole');
  };

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route
            path="/login"
            element={isAuthenticated && userRole ? <Navigate to="/admin-dashboard" /> : <Login onLogin={handleLogin} />}
          />
          <Route
            path="/admin-dashboard"
            element={isAuthenticated && userRole ? <AdminDashboard onLogout={handleLogout} /> : <Navigate to="/login" />}
          />
          <Route
            path="/doctor-reimbursements"
            element={isAuthenticated && userRole ? <DoctorReimbursements /> : <Navigate to="/login" />}
          />
          <Route
            path="/payroll"
            element={isAuthenticated && userRole ? <Payroll /> : <Navigate to="/login" />}
          />
          <Route
            path="/patients"
            element={isAuthenticated && userRole ? <Patients /> : <Navigate to="/login" />}
          />
          <Route
            path="/employees"
            element={isAuthenticated && userRole ? <Employees /> : <Navigate to="/login" />}
          />
          <Route
            path="/patient-sessions"
            element={isAuthenticated && userRole ? <PatientSessions /> : <Navigate to="/login" />}
          />
          <Route path="/" element={<Navigate to="/login" />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;