import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Dashboard.css';
import PatientCRUD from './PatientCRUD';
import SessionCRUD from './SessionCRUD';
import EmployeeCRUD from './EmployeeCRUD';
import DoctorCRUD from './DoctorCRUD';

function AdminDashboard({ onLogout }) {
  const [sessionsThisMonth, setSessionsThisMonth] = useState([]);
  const [sessionsCompletedThisMonth, setSessionsCompletedThisMonth] = useState([]);
  const [sessionsPending, setSessionsPending] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [username, setUsername] = useState('');
  const [activeComponent, setActiveComponent] = useState('dashboard');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        setError('');
        const user = JSON.parse(localStorage.getItem('user'));
        setUsername(user.username);
        if (activeComponent === 'dashboard') {
          await Promise.all([
            fetchSessionsThisMonth(),
            fetchSessionsCompletedThisMonth(),
            fetchSessionsPending()
          ]);
        }
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
        setError('Failed to load dashboard data. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [activeComponent]);

  const fetchSessionsThisMonth = async () => {
    try {
      const response = await axios.get('/api/sessions-this-month', {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      console.log('Sessions this month:', response.data);
      setSessionsThisMonth(response.data);
    } catch (error) {
      console.error('Error fetching sessions this month:', error.response?.data || error.message);
      throw error;
    }
  };

  const fetchSessionsCompletedThisMonth = async () => {
    try {
      const response = await axios.get('/api/sessions-completed-this-month', {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      console.log('Sessions completed this month:', response.data);
      setSessionsCompletedThisMonth(response.data);
    } catch (error) {
      console.error('Error fetching sessions completed this month:', error.response?.data || error.message);
      throw error;
    }
  };

  const fetchSessionsPending = async () => {
    try {
      const response = await axios.get('/api/sessions-pending', {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      console.log('Sessions pending:', response.data);
      setSessionsPending(response.data);
    } catch (error) {
      console.error('Error fetching pending sessions:', error.response?.data || error.message);
      throw error;
    }
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const handleNavigation = (path) => {
    navigate(path);
  };

  if (loading) {
    return <div>Loading dashboard data...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const renderActiveComponent = () => {
    switch (activeComponent) {
      case 'dashboard':
        return (
          <div className="dashboard-content">
            <h2>Sessions this month</h2>
            <div className="scrollable-table">
              <table>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Time</th>
                    <th>Doctor</th>
                    <th>Patient</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {sessionsThisMonth.map((session) => (
                    <tr key={session.id}>
                      <td>{formatDate(session.session_date)}</td>
                      <td>{session.session_time}</td>
                      <td>{session.doctor_name}</td>
                      <td>{session.patient_name}</td>
                      <td>{session.status}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <h2>Sessions Completed this Month</h2>
            <div className="scrollable-table">
              <table>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Time</th>
                    <th>Doctor</th>
                    <th>Patient</th>
                  </tr>
                </thead>
                <tbody>
                  {sessionsCompletedThisMonth.map((session) => (
                    <tr key={session.id}>
                      <td>{formatDate(session.session_date)}</td>
                      <td>{session.session_time}</td>
                      <td>{session.doctor_name}</td>
                      <td>{session.patient_name}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <h2>Sessions Pending</h2>
            <div className="scrollable-table">
              <table>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Time</th>
                    <th>Doctor</th>
                    <th>Patient</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {sessionsPending.map((session) => (
                    <tr key={session.id}>
                      <td>{formatDate(session.session_date)}</td>
                      <td>{session.session_time}</td>
                      <td>{session.doctor_name}</td>
                      <td>{session.patient_name}</td>
                      <td>{session.status}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        );
      case 'patients':
        return <PatientCRUD />;
      case 'sessions':
        return <SessionCRUD />;
      case 'employees':
        return <EmployeeCRUD />;
      case 'doctors':
        return <DoctorCRUD />;
      default:
        return <div>Select an option from the navigation menu</div>;
    }
  };

  return (
    <div className="dashboard-container">
      <header>
        <h1>Admin Dashboard</h1>
        <p>Welcome, {username}!</p>
      </header>
      <div className="dashboard-layout">
        <nav className="dashboard-nav">
          <button onClick={() => setActiveComponent('dashboard')}>Dashboard</button>
          <button onClick={() => setActiveComponent('patients')}>Patients</button>
          <button onClick={() => setActiveComponent('sessions')}>Sessions</button>
          <button onClick={() => setActiveComponent('employees')}>Employees</button>
          <button onClick={() => setActiveComponent('doctors')}>Doctors</button>
          <button onClick={() => handleNavigation('/doctor-reimbursements')}>Doctor Reimbursements</button>
          <button onClick={() => handleNavigation('/payroll')}>Payroll</button>
          <button onClick={onLogout}>Logout</button>
        </nav>
        <main className="dashboard-main">
          {renderActiveComponent()}
        </main>
      </div>
    </div>
  );
}

export default AdminDashboard;