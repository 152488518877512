import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Login.css';
import jemtekLogo from '../assets/Jemtek logo 2.png';
import gGroupLogo from '../assets/ggrouplogo 1.png';

function Login({ onLogin }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Attempting to log in with:', { username, password });
    try {
      const response = await axios.post('/login', { username, password });
      console.log('Login response:', response.data);
      console.log('User object:', response.data.user);
      console.log('User access_group:', response.data.user?.access_group);
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('user', JSON.stringify(response.data.user));
      if (response.data.user && response.data.user.access_group) {
        onLogin(response.data.user.access_group);
        navigate('/admin-dashboard');
      } else {
        setError('Login successful, but user role is missing. Please contact support.');
      }
    } catch (error) {
      console.error('Login error:', error);
      setError('Login failed. Please check your credentials and try again.');
      console.error('Login failed:', error.response?.data);
    }
  };

  return (
    <div className="login-container">
      <div className="login-content">
        <div className="header-container">
          <div className="logo-container">
            <img src={gGroupLogo} alt="G-Group Logo" style={{width: '184px', height: '180px'}} />
          </div>       
          <div className="title-container">
            <h1 className="company-name">G-Group & Associates</h1>
          </div>
        </div>
        <h2 className="service-name">Family Health Services</h2>
        <div className="login-box">
          <h3 className="portal-name">Doctors & Partners Portal</h3>
          <h4 className="secure-login">Secure Login</h4>
          {error && <p className="error-message">{error}</p>}
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Username"
              required
            />
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              required
            />
            <button type="submit">Login</button>
          </form>
        </div>
      </div>
      <div className="footer">
        <img src={jemtekLogo} alt="Jemtek Logo" className="jemtek-logo" />
        <div className="software-development">Software Development</div>
      </div>
    </div>
  );
}

export default Login;