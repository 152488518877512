import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Dashboard.css';

function Payroll() {
  const [payrollData, setPayrollData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    fetchPayrollData();
  }, []);

  const fetchPayrollData = async () => {
    try {
      setLoading(true);
      const response = await axios.get('/api/payroll', {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setPayrollData(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching payroll data:', error);
      setError('Failed to load payroll data. Please try again later.');
      setLoading(false);
    }
  };

  const handleBack = () => {
    navigate('/admin-dashboard');
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  if (loading) {
    return <div>Loading payroll data...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="dashboard">
      <h1>Payroll</h1>
      <button onClick={handleBack}>Back to Admin Dashboard</button>
      <div className="scrollable-table">
        <table>
          <thead>
            <tr>
              <th>Employee Name</th>
              <th>Role</th>
              <th>Hours Worked</th>
              <th>Pay Period Start</th>
              <th>Pay Period End</th>
              <th>Total Pay</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {payrollData.map((entry) => (
              <tr key={entry.id}>
                <td>{entry.employee_name}</td>
                <td>{entry.role}</td>
                <td>{typeof entry.hours_worked === 'number' ? entry.hours_worked.toFixed(2) : 'N/A'}</td>
                <td>{formatDate(entry.pay_period_start)}</td>
                <td>{formatDate(entry.pay_period_end)}</td>
                <td>${typeof entry.total_pay === 'number' ? entry.total_pay.toFixed(2) : 'N/A'}</td>
                <td>{entry.status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Payroll;