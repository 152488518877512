import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Dashboard.css';

function PatientSessions() {
  const [sessionsData, setSessionsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    fetchSessionsData();
  }, []);

  const fetchSessionsData = async () => {
    try {
      setLoading(true);
      const response = await axios.get('/api/patient-sessions', {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setSessionsData(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching patient sessions data:', error);
      setError('Failed to load patient sessions data. Please try again later.');
      setLoading(false);
    }
  };

  const handleBack = () => {
    navigate('/admin-dashboard');
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  if (loading) {
    return <div>Loading patient sessions data...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="dashboard">
      <h1>Patient Sessions</h1>
      <button onClick={handleBack}>Back to Admin Dashboard</button>
      <div className="scrollable-table">
        <table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Time</th>
              <th>Patient</th>
              <th>Doctor</th>
              <th>Service Type</th>
              <th>Status</th>
              <th>Notes</th>
            </tr>
          </thead>
          <tbody>
            {sessionsData.map((session) => (
              <tr key={session.id}>
                <td>{formatDate(session.session_date)}</td>
                <td>{session.session_time}</td>
                <td>{session.patient_name}</td>
                <td>{session.doctor_name}</td>
                <td>{session.service_type}</td>
                <td>{session.status}</td>
                <td>{session.notes}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default PatientSessions;