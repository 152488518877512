import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Dashboard.css';

function DoctorReimbursements() {
  const [reimbursements, setReimbursements] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    fetchReimbursements();
  }, []);

  const fetchReimbursements = async () => {
    try {
      setLoading(true);
      const response = await axios.get('/api/doctor-reimbursements', {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setReimbursements(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching doctor reimbursements:', error);
      setError('Failed to load doctor reimbursements. Please try again later.');
      setLoading(false);
    }
  };

  const handleBack = () => {
    navigate('/admin-dashboard');
  };

  if (loading) {
    return <div>Loading doctor reimbursements...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="dashboard">
      <h1>Doctor Reimbursements</h1>
      <button onClick={handleBack}>Back to Admin Dashboard</button>
      <div className="scrollable-table">
        <table>
          <thead>
            <tr>
              <th>Doctor Name</th>
              <th>Session Date</th>
              <th>Session Time</th>
              <th>Patient Name</th>
              <th>Reimbursement Amount</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {reimbursements.map((reimbursement) => (
              <tr key={reimbursement.id}>
                <td>{reimbursement.doctor_name}</td>
                <td>{new Date(reimbursement.session_date).toLocaleDateString()}</td>
                <td>{reimbursement.session_time}</td>
                <td>{reimbursement.patient_name}</td>
                <td>${typeof reimbursement.amount === 'number' ? reimbursement.amount.toFixed(2) : 'N/A'}</td>
                <td>{reimbursement.status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default DoctorReimbursements;