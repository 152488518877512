import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Dashboard.css';

function EmployeeCRUD() {
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [formMode, setFormMode] = useState('view'); // 'view', 'create', 'edit'
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    role: '',
    hourly_rate: '',
    access_level: '',
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchEmployees();
  }, []);

  const fetchEmployees = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get('/api/employees', {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      console.log('Employees data:', response.data);  // Debug log
      setEmployees(response.data);
    } catch (error) {
      console.error('Error fetching employees:', error);
      setError('Failed to fetch employees. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleSelectEmployee = (employee) => {
    setSelectedEmployee(employee);
    setFormData({
      ...employee,
      hourly_rate: employee.hourly_rate.toString() // Ensure hourly_rate is a string for the input field
    });
    setFormMode('view');
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ 
      ...formData, 
      [name]: name === 'hourly_rate' ? parseFloat(value) || '' : value 
    });
  };

  const handleCreateNew = () => {
    setSelectedEmployee(null);
    setFormData({
      name: '',
      email: '',
      role: '',
      hourly_rate: '',
      access_level: '',
    });
    setFormMode('create');
  };

  const handleEditCurrent = () => {
    setFormMode('edit');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    try {
      const submitData = {
        ...formData,
        hourly_rate: parseFloat(formData.hourly_rate) // Ensure hourly_rate is a number
      };
      if (formMode === 'create') {
        const response = await axios.post('/api/employees', submitData, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });
        console.log('Employee created:', response.data);  // Debug log
      } else if (formMode === 'edit') {
        const response = await axios.put(`/api/employees/${selectedEmployee.id}`, submitData, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });
        console.log('Employee updated:', response.data);  // Debug log
      }
      fetchEmployees();
      setFormMode('view');
      setSelectedEmployee(null);
    } catch (error) {
      console.error('Error submitting form:', error);
      setError('Failed to submit form. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    if (window.confirm('Are you sure you want to delete this employee?')) {
      setLoading(true);
      setError(null);
      try {
        await axios.delete(`/api/employees/${selectedEmployee.id}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });
        console.log('Employee deleted:', selectedEmployee.id);  // Debug log
        fetchEmployees();
        setSelectedEmployee(null);
        setFormMode('view');
      } catch (error) {
        console.error('Error deleting employee:', error);
        setError('Failed to delete employee. Please try again.');
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="crud-container">
      <div className="table-container">
        <h2>Employees</h2>
        {loading && <p>Loading employees...</p>}
        {error && <p className="error">{error}</p>}
        {!loading && !error && (
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Email</th>
                <th>Role</th>
                <th>Hourly Rate</th>
                <th>Access Level</th>
              </tr>
            </thead>
            <tbody>
              {employees.map((employee) => (
                <tr
                  key={employee.id}
                  onClick={() => handleSelectEmployee(employee)}
                  className={selectedEmployee && selectedEmployee.id === employee.id ? 'selected' : ''}
                >
                  <td>{employee.id}</td>
                  <td>{employee.name}</td>
                  <td>{employee.email}</td>
                  <td>{employee.role}</td>
                  <td>${parseFloat(employee.hourly_rate).toFixed(2)}</td>
                  <td>{employee.access_level}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      <div className="form-container">
        <h2>{formMode === 'view' ? 'Employee Details' : formMode === 'create' ? 'Create New Employee' : 'Edit Employee'}</h2>
        {(selectedEmployee || formMode !== 'view') && (
          <form onSubmit={handleSubmit}>
            <div>
              <label htmlFor="name">Name:</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                readOnly={formMode === 'view'}
              />
            </div>
            <div>
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                readOnly={formMode === 'view'}
              />
            </div>
            <div>
              <label htmlFor="role">Role:</label>
              <input
                type="text"
                id="role"
                name="role"
                value={formData.role}
                onChange={handleInputChange}
                readOnly={formMode === 'view'}
              />
            </div>
            <div>
              <label htmlFor="hourly_rate">Hourly Rate:</label>
              <input
                type="number"
                id="hourly_rate"
                name="hourly_rate"
                value={formData.hourly_rate}
                onChange={handleInputChange}
                readOnly={formMode === 'view'}
                step="0.01"
                min="0"
              />
            </div>
            <div>
              <label htmlFor="access_level">Access Level:</label>
              <input
                type="text"
                id="access_level"
                name="access_level"
                value={formData.access_level}
                onChange={handleInputChange}
                readOnly={formMode === 'view'}
              />
            </div>
            {formMode !== 'view' && (
              <button type="submit" disabled={loading}>
                {loading ? 'Submitting...' : formMode === 'create' ? 'Create' : 'Update'}
              </button>
            )}
          </form>
        )}
        <div className="form-buttons">
          {formMode === 'view' && (
            <>
              <button onClick={handleCreateNew}>Create New Employee</button>
              {selectedEmployee && (
                <>
                  <button onClick={handleEditCurrent}>Edit Current Employee</button>
                  <button onClick={handleDelete} disabled={loading}>
                    {loading ? 'Deleting...' : 'Delete Employee'}
                  </button>
                </>
              )}
            </>
          )}
          {formMode !== 'view' && (
            <button onClick={() => {
              setFormMode('view');
              setSelectedEmployee(null);
              setFormData({
                name: '',
                email: '',
                role: '',
                hourly_rate: '',
                access_level: '',
              });
            }}>Cancel</button>
          )}
        </div>
      </div>
    </div>
  );
}

export default EmployeeCRUD;