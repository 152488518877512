import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Dashboard.css';

function SessionCRUD() {
  const [sessions, setSessions] = useState([]);
  const [selectedSession, setSelectedSession] = useState(null);
  const [formMode, setFormMode] = useState('view'); // 'view', 'create', 'edit'
  const [formData, setFormData] = useState({
    patient_id: '',
    doctor_id: '',
    session_date: '',
    session_time: '',
    status: '',
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchSessions();
  }, []);

  const fetchSessions = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get('/api/patient-sessions', {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      console.log('Sessions data:', response.data);  // Debug log
      setSessions(response.data);
    } catch (error) {
      console.error('Error fetching sessions:', error);
      setError('Failed to fetch sessions. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleSelectSession = (session) => {
    setSelectedSession(session);
    setFormData(session);
    setFormMode('view');
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleCreateNew = () => {
    setSelectedSession(null);
    setFormData({
      patient_id: '',
      doctor_id: '',
      session_date: '',
      session_time: '',
      status: '',
    });
    setFormMode('create');
  };

  const handleEditCurrent = () => {
    setFormMode('edit');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    try {
      if (formMode === 'create') {
        const response = await axios.post('/api/patient-sessions', formData, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });
        console.log('Session created:', response.data);  // Debug log
      } else if (formMode === 'edit') {
        const response = await axios.put(`/api/patient-sessions/${selectedSession.id}`, formData, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });
        console.log('Session updated:', response.data);  // Debug log
      }
      fetchSessions();
      setFormMode('view');
      setSelectedSession(null);
    } catch (error) {
      console.error('Error submitting form:', error);
      setError('Failed to submit form. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    if (window.confirm('Are you sure you want to delete this session?')) {
      setLoading(true);
      setError(null);
      try {
        await axios.delete(`/api/patient-sessions/${selectedSession.id}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });
        console.log('Session deleted:', selectedSession.id);  // Debug log
        fetchSessions();
        setSelectedSession(null);
        setFormMode('view');
      } catch (error) {
        console.error('Error deleting session:', error);
        setError('Failed to delete session. Please try again.');
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="crud-container">
      <div className="table-container">
        <h2>Sessions</h2>
        {loading && <p>Loading sessions...</p>}
        {error && <p className="error">{error}</p>}
        {!loading && !error && (
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Patient</th>
                <th>Doctor</th>
                <th>Date</th>
                <th>Time</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {sessions.map((session) => (
                <tr
                  key={session.id}
                  onClick={() => handleSelectSession(session)}
                  className={selectedSession && selectedSession.id === session.id ? 'selected' : ''}
                >
                  <td>{session.id}</td>
                  <td>{session.patient_name}</td>
                  <td>{session.doctor_name}</td>
                  <td>{new Date(session.session_date).toLocaleDateString()}</td>
                  <td>{session.session_time}</td>
                  <td>{session.status}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      <div className="form-container">
        <h2>{formMode === 'view' ? 'Session Details' : formMode === 'create' ? 'Create New Session' : 'Edit Session'}</h2>
        {(selectedSession || formMode !== 'view') && (
          <form onSubmit={handleSubmit}>
            <div>
              <label htmlFor="patient_id">Patient ID:</label>
              <input
                type="text"
                id="patient_id"
                name="patient_id"
                value={formData.patient_id}
                onChange={handleInputChange}
                readOnly={formMode === 'view'}
              />
            </div>
            <div>
              <label htmlFor="doctor_id">Doctor ID:</label>
              <input
                type="text"
                id="doctor_id"
                name="doctor_id"
                value={formData.doctor_id}
                onChange={handleInputChange}
                readOnly={formMode === 'view'}
              />
            </div>
            <div>
              <label htmlFor="session_date">Date:</label>
              <input
                type="date"
                id="session_date"
                name="session_date"
                value={formData.session_date}
                onChange={handleInputChange}
                readOnly={formMode === 'view'}
              />
            </div>
            <div>
              <label htmlFor="session_time">Time:</label>
              <input
                type="time"
                id="session_time"
                name="session_time"
                value={formData.session_time}
                onChange={handleInputChange}
                readOnly={formMode === 'view'}
              />
            </div>
            <div>
              <label htmlFor="status">Status:</label>
              <input
                type="text"
                id="status"
                name="status"
                value={formData.status}
                onChange={handleInputChange}
                readOnly={formMode === 'view'}
              />
            </div>
            {formMode !== 'view' && (
              <button type="submit" disabled={loading}>
                {loading ? 'Submitting...' : formMode === 'create' ? 'Create' : 'Update'}
              </button>
            )}
          </form>
        )}
        <div className="form-buttons">
          {formMode === 'view' && (
            <>
              <button onClick={handleCreateNew}>Create New Session</button>
              {selectedSession && (
                <>
                  <button onClick={handleEditCurrent}>Edit Current Session</button>
                  <button onClick={handleDelete} disabled={loading}>
                    {loading ? 'Deleting...' : 'Delete Session'}
                  </button>
                </>
              )}
            </>
          )}
          {formMode !== 'view' && (
            <button onClick={() => {
              setFormMode('view');
              setSelectedSession(null);
              setFormData({
                patient_id: '',
                doctor_id: '',
                session_date: '',
                session_time: '',
                status: '',
              });
            }}>Cancel</button>
          )}
        </div>
      </div>
    </div>
  );
}

export default SessionCRUD;