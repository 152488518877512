import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Dashboard.css';

function PatientCRUD() {
  const [patients, setPatients] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [formMode, setFormMode] = useState('view'); // 'view', 'create', 'edit'
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchPatients();
  }, []);

  const fetchPatients = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get('/api/patients', {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      console.log('Patients data:', response.data);  // Debug log
      setPatients(response.data);
    } catch (error) {
      console.error('Error fetching patients:', error);
      setError('Failed to fetch patients. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleSelectPatient = (patient) => {
    setSelectedPatient(patient);
    setFormData(patient);
    setFormMode('view');
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleCreateNew = () => {
    setSelectedPatient(null);
    setFormData({
      name: '',
      email: '',
      phone: '',
    });
    setFormMode('create');
  };

  const handleEditCurrent = () => {
    setFormMode('edit');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    try {
      if (formMode === 'create') {
        const response = await axios.post('/api/patients', formData, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });
        console.log('Patient created:', response.data);  // Debug log
      } else if (formMode === 'edit') {
        const response = await axios.put(`/api/patients/${selectedPatient.id}`, formData, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });
        console.log('Patient updated:', response.data);  // Debug log
      }
      fetchPatients();
      setFormMode('view');
      setSelectedPatient(null);
    } catch (error) {
      console.error('Error submitting form:', error);
      setError('Failed to submit form. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    if (window.confirm('Are you sure you want to delete this patient?')) {
      setLoading(true);
      setError(null);
      try {
        await axios.delete(`/api/patients/${selectedPatient.id}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });
        console.log('Patient deleted:', selectedPatient.id);  // Debug log
        fetchPatients();
        setSelectedPatient(null);
        setFormMode('view');
      } catch (error) {
        console.error('Error deleting patient:', error);
        setError('Failed to delete patient. Please try again.');
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="crud-container">
      <div className="table-container">
        <h2>Patients</h2>
        {loading && <p>Loading patients...</p>}
        {error && <p className="error">{error}</p>}
        {!loading && !error && (
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Email</th>
                <th>Phone</th>
              </tr>
            </thead>
            <tbody>
              {patients.map((patient) => (
                <tr
                  key={patient.id}
                  onClick={() => handleSelectPatient(patient)}
                  className={selectedPatient && selectedPatient.id === patient.id ? 'selected' : ''}
                >
                  <td>{patient.id}</td>
                  <td>{patient.name}</td>
                  <td>{patient.email}</td>
                  <td>{patient.phone}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      <div className="form-container">
        <h2>{formMode === 'view' ? 'Patient Details' : formMode === 'create' ? 'Create New Patient' : 'Edit Patient'}</h2>
        {(selectedPatient || formMode !== 'view') && (
          <form onSubmit={handleSubmit}>
            <div>
              <label htmlFor="name">Name:</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                readOnly={formMode === 'view'}
              />
            </div>
            <div>
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                readOnly={formMode === 'view'}
              />
            </div>
            <div>
              <label htmlFor="phone">Phone:</label>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                readOnly={formMode === 'view'}
              />
            </div>
            {formMode !== 'view' && (
              <button type="submit" disabled={loading}>
                {loading ? 'Submitting...' : formMode === 'create' ? 'Create' : 'Update'}
              </button>
            )}
          </form>
        )}
        <div className="form-buttons">
          {formMode === 'view' && (
            <>
              <button onClick={handleCreateNew}>Create New Patient</button>
              {selectedPatient && (
                <>
                  <button onClick={handleEditCurrent}>Edit Current Patient</button>
                  <button onClick={handleDelete} disabled={loading}>
                    {loading ? 'Deleting...' : 'Delete Patient'}
                  </button>
                </>
              )}
            </>
          )}
          {formMode !== 'view' && (
            <button onClick={() => {
              setFormMode('view');
              setSelectedPatient(null);
              setFormData({
                name: '',
                email: '',
                phone: '',
              });
            }}>Cancel</button>
          )}
        </div>
      </div>
    </div>
  );
}

export default PatientCRUD;