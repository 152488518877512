import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Dashboard.css';

function DoctorCRUD() {
  const [doctors, setDoctors] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [formMode, setFormMode] = useState('view'); // 'view', 'create', 'edit'
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    specialization: '',
    license_number: '',
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchDoctors();
  }, []);

  const fetchDoctors = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get('/api/doctors', {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      console.log('Doctors data:', response.data);  // Debug log
      setDoctors(response.data);
    } catch (error) {
      console.error('Error fetching doctors:', error);
      setError('Failed to fetch doctors. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleSelectDoctor = (doctor) => {
    setSelectedDoctor(doctor);
    setFormData(doctor);
    setFormMode('view');
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleCreateNew = () => {
    setSelectedDoctor(null);
    setFormData({
      name: '',
      email: '',
      specialization: '',
      license_number: '',
    });
    setFormMode('create');
  };

  const handleEditCurrent = () => {
    setFormMode('edit');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    try {
      if (formMode === 'create') {
        const response = await axios.post('/api/doctors', formData, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });
        console.log('Doctor created:', response.data);  // Debug log
      } else if (formMode === 'edit') {
        const response = await axios.put(`/api/doctors/${selectedDoctor.id}`, formData, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });
        console.log('Doctor updated:', response.data);  // Debug log
      }
      fetchDoctors();
      setFormMode('view');
      setSelectedDoctor(null);
    } catch (error) {
      console.error('Error submitting form:', error);
      setError('Failed to submit form. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    if (window.confirm('Are you sure you want to delete this doctor?')) {
      setLoading(true);
      setError(null);
      try {
        await axios.delete(`/api/doctors/${selectedDoctor.id}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });
        console.log('Doctor deleted:', selectedDoctor.id);  // Debug log
        fetchDoctors();
        setSelectedDoctor(null);
        setFormMode('view');
      } catch (error) {
        console.error('Error deleting doctor:', error);
        setError('Failed to delete doctor. Please try again.');
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="crud-container">
      <div className="table-container">
        <h2>Doctors</h2>
        {loading && <p>Loading doctors...</p>}
        {error && <p className="error">{error}</p>}
        {!loading && !error && (
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Email</th>
                <th>Specialization</th>
                <th>License Number</th>
              </tr>
            </thead>
            <tbody>
              {doctors.map((doctor) => (
                <tr
                  key={doctor.id}
                  onClick={() => handleSelectDoctor(doctor)}
                  className={selectedDoctor && selectedDoctor.id === doctor.id ? 'selected' : ''}
                >
                  <td>{doctor.id}</td>
                  <td>{doctor.name}</td>
                  <td>{doctor.email}</td>
                  <td>{doctor.specialization}</td>
                  <td>{doctor.license_number}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      <div className="form-container">
        <h2>{formMode === 'view' ? 'Doctor Details' : formMode === 'create' ? 'Create New Doctor' : 'Edit Doctor'}</h2>
        {(selectedDoctor || formMode !== 'view') && (
          <form onSubmit={handleSubmit}>
            <div>
              <label htmlFor="name">Name:</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                readOnly={formMode === 'view'}
              />
            </div>
            <div>
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                readOnly={formMode === 'view'}
              />
            </div>
            <div>
              <label htmlFor="specialization">Specialization:</label>
              <input
                type="text"
                id="specialization"
                name="specialization"
                value={formData.specialization}
                onChange={handleInputChange}
                readOnly={formMode === 'view'}
              />
            </div>
            <div>
              <label htmlFor="license_number">License Number:</label>
              <input
                type="text"
                id="license_number"
                name="license_number"
                value={formData.license_number}
                onChange={handleInputChange}
                readOnly={formMode === 'view'}
              />
            </div>
            {formMode !== 'view' && (
              <button type="submit" disabled={loading}>
                {loading ? 'Submitting...' : formMode === 'create' ? 'Create' : 'Update'}
              </button>
            )}
          </form>
        )}
        <div className="form-buttons">
          {formMode === 'view' && (
            <>
              <button onClick={handleCreateNew}>Create New Doctor</button>
              {selectedDoctor && (
                <>
                  <button onClick={handleEditCurrent}>Edit Current Doctor</button>
                  <button onClick={handleDelete} disabled={loading}>
                    {loading ? 'Deleting...' : 'Delete Doctor'}
                  </button>
                </>
              )}
            </>
          )}
          {formMode !== 'view' && (
            <button onClick={() => {
              setFormMode('view');
              setSelectedDoctor(null);
              setFormData({
                name: '',
                email: '',
                specialization: '',
                license_number: '',
              });
            }}>Cancel</button>
          )}
        </div>
      </div>
    </div>
  );
}

export default DoctorCRUD;